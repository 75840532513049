.box {
    float: left;
    width: 165px;
    height: 70px;
    border: solid 1px #ffffff;
    color: #ffffff;
    text-align: center;
    user-select: none;
    cursor: pointer;
}

.box .label {
    float: left;
    width: 100%;
    color: #ffffff;
    text-align: left;
    padding-top: 5px;
    padding-left: 3px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}

.box .timer {
    clear: both;
    padding-top: 3px;
}

.box .hourmin {
    font-size: 35px;
}

.box .seconds {
    vertical-align: super;
    font-size: 22px;
}

.box .icon {
    float: right;
    padding-top: 5px;
    padding-right: 5px;
}

.box .icon img {
    width: 20px;
}

.boxColorSelect {
    float: left;
    width: 30px;
    height: 30px;
    cursor: pointer;
}